import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
const App = dynamic(() => import('@/components/App/index'), { ssr: false });

export default function PageIndex() {
  return (
    <>
      <Head>
        <title>Helpfeel FAQのドラフト生成</title>
        <meta
          name="description"
          content="Writing support system with Generative AI"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          href="https://www.helpfeel.com/hubfs/favicon-solid.png"
          type="image/svg+xml"
        />
      </Head>
      <App />
    </>
  );
}
